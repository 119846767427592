.info {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  margin: 0 5%;
  font-size: 13px;
  font-family: "Nunito Regular", 'Open Sans', sans-serif;

  img {
    width: 20px;
    heigh: 20px;
    opacity: 0.3;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
