.header {
  left: 0;
  right: 0;
  display: flex;
  //flex: 0 1;
  height: 160px;
  padding: 0 5%;
  align-items: center;
  position: relative;
}
