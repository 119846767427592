.graph {
  display: flex;
  flex: 1 1;
  width: 100%;
  justify-content: flex-start;
  padding: 15px 20px;
  overflow: scroll;
  max-width: 100%;

  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text;
    width: 100%;
  }

  &__vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
  }

  &__bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    width: 12px;

    &-filler {
      display: flex;
      font-size: 10px;
      align-items: center;
      justify-content: center;
      min-height: 20px;

      &--blue {
        background: #a2aef6;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }

      &--yellow {
        background: #f6e19e;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      span {
        font-size: 10px;
        transform: rotate(-90deg);
        color: #383b3f;
      }
    }

    &-date {
      height: 30px;
      font-size: 12px;
      text-align: center;
    }
  }
}
