.word-section {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding-bottom: 100px;
  //position: relative;

  &__inner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__border {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 0;

    &--left {
      display: flex;
      flex: 1;
      border-right: 3px dashed rgba(0,0,0,0);
      transition: border-color .3s ease-in-out;
    }

    &--right {
      display: flex;
      flex: 1;
      border-left: 3px dashed rgba(0,0,0,0);
      transition: border-color .3s ease-in-out;
    }
  }
}
