.nav {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  z-index: 2;

  &__wrapper {
    display: flex;
    list-style: none;
    padding: 0;
  }
  &__link {
    font-size: 16px;
    padding: 0 15px;
    font-family: 'Nunito Semi Bold', sans-serif;
  }
}
