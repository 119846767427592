.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;
  background: $white;
  z-index: 2;

  &__border {
    position: absolute;
    border: 2px dashed rgba(0,0,0,0);
    transition: border-color .3s ease-in-out;
    z-index: 2;

    &--left {
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: 50%;
      border-top-left-radius: 1.19rem;
      border-bottom-left-radius: 1.19rem;
      border-right: none;
    }

    &--right {
      top: -5px;
      bottom: -5px;
      right: -5px;
      left: 50%;
      border-top-right-radius: 1.19rem;
      border-bottom-right-radius: 1.19rem;
      border-left: none;
    }
  }

  &__inner {
    position: relative;
    width: 400px;
    height: 200px;
    border-radius: 1rem;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;

    &--big {
      width: 600px;
      height: 300px;
    }
  }
  &__id {
    font-family: "Nunito Bold", sans-serif;
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 13px;
    opacity: 0.2;
  }
  &__text {
    margin-top: 18px;
    font-size: 45px;
    font-family: "Nunito Extra Bold", sans-serif;
    letter-spacing: 1px;
  }
  &__translation {
    font-size: 18px;
    font-family: "Nunito Bold", sans-serif;
    letter-spacing: 1px;
    opacity: 0.5;
    transform: scaleY(0);
    transition: transform .2s ease-in-out;

    &--show {
      transform: scaleY(1);
    }
  }
}
